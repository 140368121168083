import { observable, action } from 'mobx';
import { ApplicationStatusType } from 'constants/constants';
import Model from '../Model';
import { ApplicationStore } from '../../stores/ApplicationStore';
import { ModelList } from '../ModelList';
import { ModelItem } from '../ModelItem';
import { User } from './UserModel';
import { UnitImage } from './UnitImage';
import { Payment } from './PaymentModel';
import { Applicant } from './ApplicantModel';
import * as ApplicationMeta from './ApplicationMeta';
import { ApplicationInsurance } from './ApplicationInsuranceModel';
import { PaginatedModelList } from '../PaginatedModelList';
import { RentDeterminationChat } from './RentDeterninationChatModel';
import * as ApplicationPhaModel from './ApplicationPhaModel';
import { ApplicationSource } from '../../enums/ApplicationSource.enum';
import { PHAProgramme } from './PHAProgrammesModel';
import type { PHAStepConfiguration } from 'interfaces/PHAStepConfiguration.interface';

export class Application extends Model {
  static _store: ApplicationStore;

  @observable id: number;

  @observable organisation_id: number;

  @observable unit_id: number;

  @observable pha_id: number;

  @observable pha_agent_id: number;

  @observable pha_programme: PHAProgramme;

  @observable programme_offered_for_months: number;

  @observable unit_name: string;

  @observable unit_full_address: string;

  @observable status: ApplicationStatusType;

  @observable true_status: ApplicationStatusType;

  @observable report_status: string;

  @observable agree_to_terms_and_conditions: boolean;

  @observable address_applied_for: string;

  @observable monthly_rent: number;

  @observable fee_processed: boolean;

  @observable application_fee: number;

  @observable move_in_date: string;

  @observable report_url: string;

  @observable mini_report_url: string;

  @observable test_report_url: string;

  @observable unit_images = new ModelList<UnitImage>(UnitImage);

  @observable additional_information: any;

  @observable sentilink_scores: any;

  @observable recommendation: any;

  @observable matrix_score: number;

  @observable payments = new ModelList<Payment>(Payment);

  @observable applicants = new ModelList<Applicant>(Applicant);

  @observable completed_at: string;

  @observable comp_report: string;

  @observable pha_comp_report: string;

  @observable reapplied: boolean;

  @observable agent_id: number;

  @observable invited_by: number;

  @observable meta: ApplicationMeta.ApplicationMeta;

  @observable active_subscription: boolean;

  @observable uploaded_insurance_url: string;

  @observable source: ApplicationSource;

  @observable unread_count: number;

  @observable can_create_new_proposal: boolean;

  @observable application_pha: ApplicationPhaModel.ApplicationPha;

  @observable pha_step_configuration: PHAStepConfiguration;

  @observable chats = new PaginatedModelList<RentDeterminationChat>(
    RentDeterminationChat,
  );

  @observable insurance = new ModelItem<ApplicationInsurance>(
    ApplicationInsurance,
  );

  @observable detailed_status: any;

  @observable agent_of_record = new ModelItem<User>(User);

  @action
  setAgreeToTerms(b: boolean) {
    this.agree_to_terms_and_conditions = b;
  }

  hasConfirmedInformation(loggedInUser: User) {
    const loggedInApplicant = this.loggedInApplicant(loggedInUser);
    return loggedInApplicant && loggedInApplicant.information_confirmed;
  }

  hasActiveSubscription() {
    return this.active_subscription;
  }

  hasSignedLease(loggedInUser: User) {
    const loggedInApplicant = this.loggedInApplicant(loggedInUser);
    return loggedInApplicant && !!loggedInApplicant.signed_document_url;
  }

  loggedInApplicant(loggedInUser: User) {
    const applicantsLength = this.applicants.items.length;
    if (!applicantsLength) {
      return null;
    }
    return this.applicants.items.find((a) => a.user_id === loggedInUser.id);
  }

  get applicantNames() {
    const applicantsLength = this.applicants.items.length;
    if (!applicantsLength) {
      return null;
    }
    let primaryApplicant = this.applicants.items[0];
    const primaryApplicants = this.applicants.items.filter((a) => a.is_primary);
    if (primaryApplicants.length > 0) {
      primaryApplicant = primaryApplicants[0];
    }
    if (applicantsLength === 1) {
      return primaryApplicant.fullname;
    }
    return `${primaryApplicant.fullname} and ${
      this.applicants.items.length - 1
    } other`;
  }
}
