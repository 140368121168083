import { action, observable, computed } from 'mobx';
import ApiRoutes from '../routes/api/ApiRoutes';
import { PaginatedModelList } from '../models/PaginatedModelList';
import Store from './Store';
import { Building, BuildingType } from '../models/entities/BuildingModel';
import { Unit } from '../models/entities/UnitModel';
import { ModelItem } from '../models/ModelItem';
import { addIncludesToParams, getUnitFullAddress } from '../utils/helpers';

export enum BuildingStoreIncludes {
  CITIES = 'city',
  STATES = 'state',
}

export class BuildingStore extends Store<Building> {
  constructor() {
    super();
    Building._store = this;
  }

  @observable buildingPaginatedList = new PaginatedModelList<Building>(
    Building,
  );

  @observable buildingItem = new ModelItem<Building>(Building);

  @observable buildingUnitsPaginatedList = new PaginatedModelList<Unit>(Unit);

  @observable buildingsDropdownList = new PaginatedModelList<Building>(
    Building,
  );

  @observable
  tanentMonitoringBuildingsPaginatedList = new PaginatedModelList<Building>(
    Building,
  );

  @computed get buildings() {
    return this.buildingPaginatedList.items;
  }

  @computed get tanentMonitoringBuildings() {
    return this.tanentMonitoringBuildingsPaginatedList.items;
  }

  @computed get building() {
    return this.buildingItem.item;
  }

  @computed get newBuildings() {
    if (!this.buildingsDropdownList.items.length) {
      this.buildingsDropdownList.load(ApiRoutes.buildings.list);
    }
    return this.buildingsDropdownList?.items;
  }

  @action
  async fetchBuildingUnits(params?: { [key: string]: any }) {
    const building = this.get(params.buildings);
    building.units.load(ApiRoutes.units.list, params);
  }

  @action
  async fetchBuildings(
    params?: { [key: string]: any },
    forceRefresh?: boolean,
  ) {
    return this.buildingPaginatedList.load(ApiRoutes.buildings.list, params, {
      forceRefresh,
    });
  }

  @action
  async fetchBuildingsDirectly(
    params?: { [key: string]: any },
    forceRefresh?: boolean,
  ) {
    return this.apiService.get(ApiRoutes.buildings.list, params, {
      forceRefresh,
    });
  }

  @action async fetchBuildingFromId(
    id: number,
    include: BuildingStoreIncludes[] = [],
    appendIntoStore = false,
  ) {
    const promise = this.buildingItem.load(
      ApiRoutes.buildings.show(id),
      addIncludesToParams({}, include),
    );
    if (appendIntoStore) {
      promise.then(() => {
        const alreadyPresent = this.buildingPaginatedList.items.find(
          (item) => item.id === this.buildingItem.item?.id,
        );
        if (!alreadyPresent) {
          this.buildingPaginatedList.appendItem(this.buildingItem.item);
        }
      });
    }
    return promise;
  }

  @action async fetchAndAppendSelectedBuilding(
    id: number,
    include: BuildingStoreIncludes[] = [],
  ) {
    await this.buildingItem.load(
      ApiRoutes.buildings.show(id),
      addIncludesToParams({}, include),
    );
    return this.buildingsDropdownList.appendItem(this.buildingItem.item);
  }

  @action
  async updateElement(building: Building, body: { [key: string]: any }) {
    const formData = new FormData();
    Object.keys(body).forEach((key) => formData.append(key, body[key]));
    const response = await this.apiService.patch(
      ApiRoutes.buildings.show(building.id),
      formData,
    );

    building.updateFromJson(response.data);
    const units = building.units.items;
    if (building.type === BuildingType.MULTI_UNIT) {
      units.map((unit) => {
        unit.updateFromJson({
          id: unit.id,
          unit_full_address: getUnitFullAddress(unit, building),
        });
      });
    } else {
      units[0]?.updateFromJson({
        id: units[0].id,
        status_id: body.status_id,
        monthly_rent: +body.monthly_rent,
        security_deposit: +body?.security_deposit,
        cats_allowed: body?.cats_allowed,
        dogs_allowed: body?.dogs_allowed,
        bedrooms: body?.number_of_bedrooms,
        bathrooms: body?.number_of_bathrooms,
      });
    }
    return building;
  }

  @action
  async createBuilding(body: { [key: string]: any }) {
    const formData = new FormData();
    Object.keys(body).forEach((key) => formData.append(key, body[key]));
    const response = await this.apiService.post(
      ApiRoutes.buildings.list,
      formData,
    );
    const building = Building.fromJson(response.data) as Building;
    this.buildingPaginatedList.appendItem(building);
    return building;
  }

  @action
  async removeImage(building: Building, body: { [key: string]: any }) {
    const response = await this.apiService.delete(
      ApiRoutes.buildingUnit.deleteImage(+building.id),
      null,
      null,
      null,
      body,
    );
    return response;
  }

  @action
  async addImage(building: any, body: { [key: string]: any }) {
    building.setUpdating(true);
    const formData = new FormData();
    Object.keys(body).forEach((key) => formData.append(key, body[key]));
    const response = await this.apiService.post(
      ApiRoutes.buildingUnit.addImage(+building.id),
      formData,
    );
    building.updateFromJson({
      building_images: [...building.building_images?.items, response],
    });
    building.setUpdating(false);
    return response;
  }

  @action
  async updateBuildingUnit(buildingId: number, unit: Unit): Promise<void> {
    const building = this.get(buildingId);
    building.units.appendItem(unit);
  }

  @action
  async transferProperty(organisation_id: number, building_id: number) {
    return this.apiService.post(
      ApiRoutes.buildings.transferProperty(building_id),
      {
        organisation_id,
      },
    );
  }

  @action
  clearStore(): void {
    this.buildingPaginatedList = new PaginatedModelList<Building>(Building);
    this.buildingItem = new ModelItem<Building>(Building);
    this.buildingUnitsPaginatedList = new PaginatedModelList<Unit>(Unit);
    this.buildingsDropdownList = new PaginatedModelList<Building>(Building);
    this.tanentMonitoringBuildingsPaginatedList = new PaginatedModelList<
      Building
    >(Building);
  }
}
